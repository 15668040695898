import Footer from "./footer";
import Navbar from "./navbar";

const Main = ({ children }) => {
  return (
    <>
      <Navbar />
      <main className="min-h-[100vh] w-full">{children}</main>
      <Footer />
    </>
  );
};

export default Main;
