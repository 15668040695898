import FAQ from "./FAQ";
import About from "./about";
import Banner from "./banner";
import Flow from "./flow";
import Join from "./join";

const Home = () => {
  return (
    <>
      <div className="">
        <Banner />
        <About />
        <Flow />
        <Join />
        <FAQ />
      </div>
    </>
  );
};

export default Home;
