import Main from "layout";
import NotFound from "page/404";
import Home from "page/home";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS?.init();
    AOS?.refresh();
  }, []);
  return (
    <Main>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </Main>
  );
}

export default App;
