import { useTranslation } from "react-i18next";
import bg from "../../assets/kv_logo.svg";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="kv">
        <div className="kv__inner">
          <div>
            <img src={bg} alt="" />
          </div>
          <div>
            <strong>{t("home.p1")}</strong>
            <p>{t("home.p2")}</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
