import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import English from "./locales/en/translation.json";
import Japan from "./locales/ja/translation.json";

const resources = {
  en: {
    translation: English,
  },
  ja: {
    translation: Japan,
  },
};

const lang = localStorage.getItem('lang');

i18n
  .use(initReactI18next)

  .init({
    resources,
    fallbackLng: lang || 'ja',
    debug: true,
    lng: lang || 'ja',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
