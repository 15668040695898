import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { useTranslation } from "react-i18next";
import IMGJA from "../assets/JAPAN.png";
import IMGEN from "../assets/EN.png";
import { clsx } from "clsx";

const langs = [
  { value: "en", icon: IMGEN },
  { value: "ja", icon: IMGJA },
];

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState("");
  const [open, setOpen] = useState(false);

  const handleChangleLang = (l) => {
    i18n.changeLanguage(l);
    setLang(l);
    setOpen(false);
    localStorage.setItem("lang", l);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const lang = localStorage.getItem("lang");
      setLang(lang || "ja");
    }
  }, []);

  return (
    <header className="header">
      <div className="header__row">
        <div className="header__brand">
          <a href="/">
            <Logo />
          </a>
        </div>
        <ul className="header__menu">
          <li>
            <a href="#about">
              <span>{t("nav.about")}</span>
            </a>
          </li>
          <li>
            <a href="#flow">
              <span>{t("nav.flow")}</span>
            </a>
          </li>
          <li>
            <a href="#how">
              <span>{t("nav.howToJoin")}</span>
            </a>
          </li>
          <li>
            <a href="#faq">
              <span>{t("nav.faq")}</span>
            </a>
          </li>
          <div className="fixed right-6 border border-black p-2 cursor-pointer">
            <div className="relative w-12 aspect-square border-gradient-primary border-gradient-circle p-1">
              <img
                src={lang === "ja" ? IMGJA : IMGEN}
                alt="lang en"
                className="w-full"
                onClick={() => setOpen((prev) => !prev)}
              />

              <div
                className={clsx(
                  "absolute left-0 top-14 bg-white rounded-xl border-gray-700 flex-col overflow-hidden",
                  open ? "flex" : "hidden"
                )}
              >
                {langs?.map((lang) => (
                  <div
                    key={lang.value}
                    className={clsx(
                      "p-2 hover:bg-gradient-primary",
                      lang === lang.value && "bg-gradient-primary"
                    )}
                    onClick={() => handleChangleLang(lang.value)}
                  >
                    <img src={lang.icon} alt={`lang ${lang.value}`} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ul>
        <div className="header__cv">
          <a href="#fee">
            <span>{t('nav.join')}</span>
          </a>
        </div>
        <div className="md:hidden fixed right-6 border border-black p-2 cursor-pointer">
          <div className="relative w-[32px] md:w-12 aspect-square border-gradient-primary border-gradient-circle p-1">
            <img
              src={lang === "ja" ? IMGJA : IMGEN}
              alt="lang en"
              className="w-full"
              onClick={() => setOpen((prev) => !prev)}
            />

            <div
              className={clsx(
                "absolute left-0 top-14 bg-white rounded-xl border-gray-700 flex-col overflow-hidden",
                open ? "flex" : "hidden"
              )}
            >
              {langs?.map((lang) => (
                <div
                  key={lang.value}
                  className={clsx(
                    "p-2 hover:bg-gradient-primary",
                    lang === lang.value && "bg-gradient-primary"
                  )}
                  onClick={() => handleChangleLang(lang.value)}
                >
                  <img src={lang.icon} alt={`lang ${lang.value}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
