import { ReactComponent as Twitter } from "../assets/twitter.svg";
import { ReactComponent as Discord } from "../assets/discord.svg";
import Img01 from "../assets/btn01.svg";
import Img02 from "../assets/btn02.svg";
import Bg from "../assets/bg.svg";
import Title from "../assets/title.svg";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="footer__upper" id="fee">
        <img src={Bg} alt="" />
        <div>
          <div className="inner">
            <h2>
              <img src={Title} alt="" />
            </h2>
            <p>
              {t("home.p31")} <br />
              {t("home.p32")}
            </p>
            <div className="footer__row">
              <div>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSc7LtMk1thlMKH6H9WPiKYLobAQenYGgymBO58dwldA7J-lMg/viewform"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Img02} alt="" />
                </a>
              </div>
              <div>
                <a
                  href="https://discord.gg/bmd7EmvhQ2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Img01} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="w-full h-[180px] md:h-[310px] bg-black">
        <div className="container h-full mx-auto flex flex-col gap-2 md:gap-8 justify-center items-center">
          <div className="flex gap-4 justify-center items-center mb-6">
            <a
              href="https://twitter.com/JACKITgg"
              target="_blank"
              rel="noreferrer"
            >
              <p className="!w-[23px]">
                <Twitter />
              </p>
            </a>
            <a
              href="https://discord.gg/bmd7EmvhQ2"
              target="_blank"
              rel="noreferrer"
            >
              <p className="!w-[30px]">
                <Discord />
              </p>
            </a>
          </div>
          {i18n?.language === "ja" ? (
            <p className="text-[#696969] md:text-[15px] text-[12px]">
              {/* {t("home.p33")} */}
              JACK ITの活動は
              <a
                href="https://scalably.com/"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Scalably社
              </a>
              のマーケティング活動の一環です。
            </p>
          ) : (
            <p className="text-[#696969] md:text-[15px] text-[12px]">
              {/* {t("home.p33")} */}
              JACK IT is a part of 
              <a
                href="https://scalably.com/"
                target="_blank"
                rel="noreferrer"
                className="underline px-2"
              >
               Scalably's
              </a>
              marketing campaign.
            </p>
          )}
          <p className="text-[#696969] md:text-[15px] font-bold text-[12px]">
            {t("home.p3")}
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
