import React from "react";

const NotFound = () => {
  return (
    <div className="flex justify-center items-center w-full h-[70vh] text-3xl text-gray-500 font-bold">
      Page Not Found !
    </div>
  );
};

export default NotFound;
